<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト(非住宅版)'"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="false"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'躯体の外皮性能等 - 開⼝部（仕様に基づく）'"
    >
    
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">仕様に基づく開⼝部の性能</h2>
            </div>
            <form class="uq_opening">
                <div class="bl_input_container">
                    <app-label
                        name="material"
                        label="建具の仕様"
                    />
                    <app-select
                        v-model="material"
                        ref="jest-building-opening-material"
                        :vmodel-label="vModelMaterialLabel"
                        class="uq_wd_30em"
                        :label="vModelMaterialLabel"
                    >
                        <app-select-option
                            :value="null"
                            v-model="material"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="item in materialCategories"
                            :key="'material' + item.code"
                            :value="item.code"
                            v-model="material"
                        >
                            {{item.category}}
                        </app-select-option>
                    </app-select>
                </div>
                <app-button
                    name="事業者リンク（建具の仕様）"
                    @click="toMaterialLinks"
                    ref="jest-opening-material-links"
                        class="inline wd_200px"
                />
                <hr class="hp_flexspacer" />
                <div class="bl_input_container">
                    <app-label
                        name="glass"
                        label="ガラスの仕様"
                    />
                    <app-select
                        v-model="glass"
                        ref="jest-building-opening-glass"
                        :vmodel-label="vModelGlassLabel"
                        class="uq_wd_30em"
                        :label="vModelGlassLabel"
                    >
                        <app-select-option
                            :value="null"
                            v-model="glass"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="item in glassCategories"
                            :key="'glass' + item.code"
                            :value="item.code"
                            v-model="glass"
                        >
                            {{item.category}}
                        </app-select-option>
                    </app-select>
                </div>
                <app-button
                    name="事業者リンク（ガラスの仕様）"
                    @click="toGlassLinks"
                    ref="jest-opening-glass-links"
                        class="inline wd_200px"
                />
            </form>
            <table v-if="isAnyTable" class="uq_opening_table">
                <tr>
                    <th>中空層の厚さ [mm]</th>
                    <th>ガラス建築確認記号</th>
                    <th>窓の熱貫流率 [W/(㎡K)]<br>ガラスのみ</th>
                    <th>窓の熱貫流率 [W/(㎡K)]<br>ブラインドあり</th>
                    <th>窓の日射熱取得率<br>ガラスのみ</th>
                    <th>窓の日射熱取得率<br>ブラインドあり</th>
                </tr>
                <tr
                    v-for="item in glasses"
                    :key="'glasses' + item.code"
                >
                    <td>{{item.midair_layer}}</td>
                    <td>{{item.glass_construction_confirmation}}</td>
                    <td>{{item.u_value_glass}}</td>
                    <td>{{item.u_value_blind}}</td>
                    <td>{{item.eta_value_glass}}</td>
                    <td>{{item.eta_value_blind}}</td>
                </tr>
            </table>
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    class="cancel"
                    @click="moveTo('/building/links')"
                    ref="jest-opening-cancel"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P023 開口部一覧
 * 
 * [イベント処理]
 * moveTo...指定したパスに遷移する
 * search...選択された建具×ガラスの値を検索して表示
 * toMaterialLinks...選択されている建具のリンク一覧に遷移
 * toMaterialLinks...選択されているガラスのリンク一覧に遷移
 * toLinks...選択されている建具・ガラスのリンク一覧に遷移する際の遷移処理
 */
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties } from '@/util'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'

export default {
    name:'PublicBuildingOpening',
    mixins:[Progress],
    watch:{
        glass(){
            this.search()
        },
        material(){
            this.search()
        },
    },
    computed:{
        ...mapGetters({
            materialCategories:'links/materialCategories',
            selectedCategoryCode:'links/selectedCategoryCode',
            glassCategories:'links/glassCategories',
            data:'links/searchBuildingOpeningparams',
            glasses:'links/glasses'
        }),
        ...mapComputedProperties([
            'material',
            'glass'
        ]),
        vModelMaterialLabel(){
            const selectedMaterialCategories = this.materialCategories.filter(x => x.code === this.material)
            return selectedMaterialCategories[0] ? selectedMaterialCategories[0].category : '選択してください'
        },
        vModelGlassLabel(){
            const selectedGlassCategories = this.glassCategories.filter(x => x.code === this.glass)
            return selectedGlassCategories[0] ? selectedGlassCategories[0].category : '選択してください'
        },
        isAnyTable(){
            return this.glasses.length !== 0 && this.material != null && this.glass != null
        },
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        if (this.selectedCategoryCode <= 2006) {
            this.material = this.selectedCategoryCode
            this.glass = null
        } else {
            this.material = null
            this.glass = this.selectedCategoryCode
        }
        await this.updateCategories('building')
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            update:'links/update',
            updateCategories:'links/updateCategories',
            getGlasses:'links/getGlasses'
        }),
        // 指定したパスに遷移する
        moveTo(path){
            move(this.$router, path)
        },
        // 選択された建具×ガラスの値を検索して表示
        search(){
            this.getGlasses({
                type:'building',
                material:this.material,
                glass:this.glass
            })
        },
        toMaterialLinks(){
            this.toLinks(this.materialCategories.find(item => item.code === this.material))
        },
        toGlassLinks(){
            this.toLinks(this.glassCategories.find(item => item.code === this.glass))
        },
        toLinks(target){
            this.update({
                    selectedCategoryCode:target.code,
                    selectedCategoryName:target.category,
                    showingLevel1:target.level1,
                    showingLevel2:target.level2,
                    showingLevel3:target.level3 !== '-' ? target.level3 : null,
                })
                .then(() => {
                    this.moveTo('/building/links/organizations')
                })
        }
    }
}
</script>

<style scoped>
.uq_opening{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #CDD6DD;
}
.uq_opening>h4{
    width: 100%;
    margin: 8px 0;
}
.uq_opening .el_label{
    display: inline-block;
    width: 7em;
}
.uq_opening >>> button.wd_200px{
    width: 250px;
    margin-left: auto;
}
.hp_flexspacer{
    margin: 8px 0;
}
.uq_opening_table{
    width: 100%;
    text-align: center;
}
.select_wrapper.uq_wd_30em >>> .select_btn{
    width:25em;
    min-width: 25em;
    margin: 0;
}
</style>